<script>

import KmmProfileMenu from '@/components/common/KmmProfileMenu.vue';

export default {
    name: 'KmmNav',
    components: {KmmProfileMenu},
    data() {
        return {
            modalMaterialsTab: '',
        }
    },
    mounted() {
        /*document.querySelector('label[for="nav"]').addEventListener('click', (e) => {
            document.querySelector('nav.kmm').classList.toggle('expand');
        });*/
    },
}
</script>

<template>
    <KmmProfileMenu />

    <nav class="kmm" style="margin: 0px -15px 10px;">
        <ul>
            <li class="logo">
                <router-link to="/">
                    <img src="@/assets/img/logo2icon.png" alt="logo" style="height: 30px" />
                    <img src="@/assets/img/logo2text.png" alt="logo" style="height: 26px; margin-left: 4px" class="desktop" />
                </router-link>
            </li>

            <!--<li>«&nbsp;Назад</li> -->
            <li v-if="!this.$store.me.isDefense && this.$store.me.band?.id"><router-link to="/subjects"><i>📚</i><b>Навчання</b></router-link></li><!--🎓-->
            <li><router-link to="/quiz"><i>🎮</i><b>Quiz</b></router-link></li>
            <li v-if="$store.class.course.is_junior || ['5', '6', '7', '8', '9'].includes($store.class.course.level)"><router-link :to="{name: 'craftMaterials'}"><i>🎨</i><b>Конкурси</b></router-link></li>
            <!-- Майстер-класи -->
            <!--<li><i>📚</i>Матеріали</li> -->
            <!--<li><a @click="modalMaterialsTab = 'Правила безпеки'; $refs.modalMaterialsRef?.open();"><i>🚸</i><b>Правила безпеки</b></a></li>-->
            <li v-if="$store.class.course.is_junior"><a @click="modalMaterialsTab = 'Активності'; $refs.modalMaterialsRef?.open();"><i>💁🏼</i><b>Активності</b></a></li>
            <li v-if="$store.me.is_teacher"><router-link to="/journal"><i>📓</i><b>Журнал</b></router-link></li>
            <li :data-count="$store.me.diplomas_count"><router-link to="/diplomas"><i>🏆</i><b>Дипломи</b></router-link></li>
            <li><router-link to="/profile"><i>⚙️</i><b>Профіль</b></router-link></li>
            <li><a href="/auth" @click="$store.logout();"><i>🔐</i><b>Вийти</b></a></li>
        </ul>
        <label for="nav" onclick="this.closest('nav.kmm')?.classList.toggle('expand')">☷</label>
    </nav>
    <!-- 💖 ☒ ☰ ☷ ⚞ -->
    <qip-modal ref="modalMaterialsRef">
        <materials-fast v-if="modalMaterialsTab" :tab-name="modalMaterialsTab" :key="modalMaterialsTab" />
    </qip-modal>
</template>

<style scoped>
/*.resizable { overflow: auto; resize: both; }*/
/*.container { box-sizing: border-box; border: 1px solid #ccc; margin: 0 auto; padding: 10px; }*/

nav.kmm {
    --nav-size: 1.1em;
    --nav-height: 32px;
    --nav-color: #410376;
    /*font-family: sans-serif;*/
    font-size: clamp(11px, 3.55dvw, var(--nav-size));
    display: flex;
    position: sticky;
    top: 0;
    padding: 4px 8px;
    background: #fefefe;
    border-top: 1px solid #cfcfcf;
    border-bottom: 1px solid #cfcfcf;
    z-index: 9;
}
nav.kmm > * { height: var(--nav-height); }
nav.kmm ul { overflow: hidden; width: 100%; display: flex; flex-wrap: wrap; justify-content: space-between; gap: 4px; list-style: none; margin: 0; padding: 0; /*transition: all .7s linear;*/}
nav.kmm.expand ul { height: auto; }
nav.kmm.expand ul li { height: auto; }
nav.kmm ul li { height: var(--nav-height); display: flex; justify-content: center; align-items: center; cursor: pointer; white-space: nowrap; box-sizing: border-box; color: var(--nav-color); }
nav.kmm ul li:not(.logo) { padding: 0 4px; }

nav.kmm ul li:not(.logo):hover {
    i { transform: scale(1.5) translate(-2px); }
}
nav.kmm ul li:has(.router-link-active) a b, nav.kmm ul li:not(.logo):hover a b { color: var(--what-color-name1); /*border-bottom: 2px solid var(--primary-text);*/ }

nav.kmm ul li a { display: flex; align-items: center; text-decoration: none; /*height: 100%;*/ }
nav.kmm ul li i { font: 80% normal; margin-right: 3px; transition: all 0.3s linear; }
nav.kmm ul li b { font-weight: 500; border-bottom: 2px solid #fff; }

nav.kmm label[for="nav"] { font: bold 115% sans-serif; margin: 0 5px 0 20px; line-height: var(--nav-height); color: #FF9800; cursor: pointer; position: relative; }
nav.kmm.expand label[for="nav"]:before { content: '⤴'; position: absolute; top: -2px; left: 0; right: -2px; background: #fff; text-shadow: 1px 0 1px #FF9800; }

@container (width >= 1100px) {
    /* @todo Detect on JS */
    nav.kmm { border-radius: 10px; }
    nav.kmm { display: block; /*padding-left: 10px; padding-right: 10px;*/ }
    nav.kmm label[for="nav"] { display: none; }
    /*nav.kmm label[for="nav"] { display: flex; justify-content: center; border-top: 1px solid #ccc; } */
}

/*@container (width < 1050px) { nav.kmm { align-items: flex-start; } nav.kmm > ul li:nth-child(1) { order: 1; } }*/

@media (max-width: 460px) {
    nav.kmm {
        li.logo img.desktop { display: none; }
        li.logo a img { height: 24px !important; }
        li.logo:hover img.desktop { display: block; }
    }
}

</style>
