import axios from 'axios';
import VueAxios from 'vue-axios'
import Vue, { ref } from 'vue';
//import app from '@/App.vue';

//import { inject } from 'vue';
//inject('notify'); // Not works!

export default function(app) {
    const $notify = app.config.globalProperties.$notify;
    const $store = app.config.globalProperties.$store;

    axios.defaults.baseURL = process.env.VUE_APP_BASE_API_URL;

    if (process.env.VUE_APP_IS_RASPBERRY_MODE) {
        const apiURL = new URL(process.env.VUE_APP_BASE_API_URL);
        apiURL.hostname = location.hostname;
        axios.defaults.baseURL = apiURL.href;
    }

    app.config.globalProperties.waitAjax = 0;
    app.config.globalProperties.waitAjaxRef = ref(0);

    // - - - - -
    axios.interceptors.request.use(request => {
        app.config.globalProperties.waitAjax++;
        app.config.globalProperties.waitAjaxRef.value++;
        return request;
    });
    axios.interceptors.response.use(response => {
        app.config.globalProperties.waitAjax--;
        app.config.globalProperties.waitAjaxRef.value--;
        return response;
    }, (err) => {
        app.config.globalProperties.waitAjax--;
        app.config.globalProperties.waitAjaxRef.value--;
        console.log('Request Error:', app.config.globalProperties.waitAjax);
        return Promise.reject(err);
    });
    // - - - - -

    axios.defaults.params = {};
    axios.interceptors.request.use(
        (config) => {
            //const accessKey = sessionStorage.getItem('accessKey') || localStorage.getItem('accessKey');
            if ($store.accessKey) {
                config.headers['X-Access-Key'] = $store.accessKey;
                //config.headers['Authorization'] = `Bearer ${token}`;
            }

            if ($store.contextClassId) {
                //console.log('contextClassId:', $store.contextClassId)
                config.headers['X-Class-Id'] = $store.contextClassId;
                config.params['cid'] = $store.contextClassId;
            }

            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    axios.interceptors.response.use(function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
    }, function (err) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        //console.log('interceptors.response: error:', err, err.response);
        console.error('err:', err); // err.name : "AxiosError"

        if (!err.response) { // message, name, config, request
            // err.message // Network Error
            // err.name    // AxiosError
            //JSON.stringify(err)
            //alert(err.message); // @todo use throttle
        }

        if (err.code === 'ERR_NETWORK') {
            let reason = `<b>Ой!</b> Немає зв’язку з сервером. Можливо виконуються вимушені технічні роботи і доведеться дещо зачекати.<br>${err.code}: ${err.message}`;
            $notify?.fail(reason);
        }

        if (err.response?.status === 403) {
            if (localStorage.getItem('accessKey')) {
                localStorage.setItem('forbiddenAccessKey', localStorage.getItem('accessKey'));
                localStorage.removeItem('accessKey');
            }

            $notify?.fail(err.response.data.message + '<br>Переконайтесь, що ключ введено правильно.'); // @note Не відображається якщо далі буде перенаправлення на /auth
            alert(err.response.data.message); // Ускладнюємо часті спроби.

            if (location.pathname !== '/auth') {
                location.href = '/auth';
            }
        }

        if (err.response?.status === 429) { // Request failed with status code 429
            $notify?.fail('<b>Зачекайте!</b> Забагато запитів до серверу!<br>Будь ласка зачекайте хвилинку і спробуйте ще раз.<br>Error: 429 Too Many Requests')
        }

        return Promise.reject(err);
    });

    app.use(VueAxios, axios);
    //export default axios;
}
